import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

import { CompanyDetailModel } from '@dps/shared/models';
import { CompanyApiService, ContractConfirmationApiService } from '@dps/core/api';
import { GetCompany, LoadActualsCount, ResetRootState, UpdateCompany } from './root.actions';
import { RootStateModel } from './root.state.model';

@State<RootStateModel>({
  name: 'root',
  defaults: {
    currentCompany: null,
    currCompanyActualsCount: 0,
  },
})
@Injectable()
export class RootState {
  constructor(
    private contractConfirmationApiService: ContractConfirmationApiService,
    private companyApiService: CompanyApiService
  ) {}

  @Selector()
  static getCompanyData(state: RootStateModel): CompanyDetailModel | null {
    return state.currentCompany;
  }
  @Selector()
  static getCompanyId(state: RootStateModel): string | null {
    return state.currentCompany?.id || null;
  }

  @Selector()
  static getCompanyActualsCount(state: RootStateModel): number {
    return state.currCompanyActualsCount;
  }

  @Selector()
  static isCompanyTimeRegistrationEnabled(state: RootStateModel): boolean {
    return state.currentCompany?.isTimeRegistrationEnabled || false;
  }

  @Selector()
  static isCompanyGroupsEnabled(state: RootStateModel): boolean {
    return state.currentCompany?.isGroupsEnabled || false;
  }

  @Selector()
  static isCompanyActualsEnabled(state: RootStateModel): boolean {
    return state.currentCompany?.isActualsEnabled || false;
  }

  @Action(GetCompany)
  getCompany(ctx: StateContext<RootStateModel>, action: GetCompany) {
    return this.companyApiService.getCompany(action.companyId).pipe(
      tap(company =>
        ctx.patchState({
          currentCompany: company,
        })
      )
    );
  }

  @Action(UpdateCompany)
  updateCompany(ctx: StateContext<RootStateModel>, action: UpdateCompany) {
    ctx.patchState({
      currentCompany: action.payload,
    });
  }

  @Action(LoadActualsCount)
  loadCompanyActualsCount(ctx: StateContext<RootStateModel>, action: LoadActualsCount) {
    const companyId = ctx.getState().currentCompany?.id;
    return this.contractConfirmationApiService
      .getContractsConfirmationsCount(companyId as string)
      .pipe(tap(count => ctx.patchState({ currCompanyActualsCount: count })));
  }

  @Action(ResetRootState)
  resetRootState(ctx: StateContext<RootStateModel>) {
    ctx.setState({
      currentCompany: null,
      currCompanyActualsCount: 0,
    });
  }
}
